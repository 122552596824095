import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailValidator} from "../../validators/EmailValidator";
import Axios, {AxiosRequestConfig} from 'axios';
import {RecaptchaComponent, RecaptchaErrorParameters, ReCaptchaV3Service} from 'ng-recaptcha';
// @ts-ignore
import {lastValueFrom, Observable} from 'rxjs';

@Component({
  selector: 'vt-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  public environment: any;
  public contactForm: FormGroup;
  public contactFormSubmitted: boolean;
  public showSuccessMessage: boolean;
  public formErrors: any;
  public subscribe: boolean;
  public readonly validationMessages: any = {
    name: {
      required: 'Name is required',
      minlength: 'Name must be at least 5 characters long',
      maxlength: 'Name cannot be more than 50 characters long'
    },
    email: {
      required: 'Email address is required',
      email: 'Invalid email address'
    },
    subject: {
      required: 'Subject is required',
      minlength: 'Subject must contain at least 10 characters',
      maxlength: 'Subject cannot be more than 80 characters'
    },
    message: {
      required: 'Message is required',
      minlength: 'Message must contain at least 20 characters',
      maxlength: 'Message cannot be more than 500 characters'
    },
    recaptcha: {
      required: 'reCAPTCHA is required',
    },
    acceptTosPrivacy: {
      required: 'You must accept the Terms of Service and Privacy Policy',
      requiredTrue: 'You must accept the Terms of Service and Privacy Policy'
    }
  };


  constructor(private fb: FormBuilder,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.environment = environment;
    this.contactFormSubmitted = false;
    this.subscribe = true;
    this.showSuccessMessage = this.contactFormSubmitted;
    this.contactForm = this.fb.group({});
    this.formErrors = {
      name: [],
      email: [],
      subject: [],
      message: [],
      recaptcha: [],
      acceptTosPrivacy: [],
      general: []
    };
  }

  ngOnInit(): void {
    this.contactFormSubmitted = false;
    this.showSuccessMessage = this.contactFormSubmitted;
    this.subscribe = true;
    this.buildForm();
  }

  public clearForm(): void {
    this.contactForm.reset();
    this.showSuccessMessage = false;
    this.contactFormSubmitted = false;
    this.subscribe = true;
  }

  private buildForm(): void {
    this.contactForm = this.fb.group({
      name: [null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(5), Validators.maxLength(50)] }],
      // tslint:disable-next-line:max-line-length
      email: [null, { updateOn: 'blur', validators: [Validators.required, EmailValidator.emailValidator, Validators.maxLength(100)] }],
      subject: [null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(10), Validators.maxLength(80)] }],
      message: [null, { updateOn: 'blur', validators: [Validators.required, Validators.minLength(20), Validators.maxLength(500)] }],
      acceptTosPrivacy: [null, Validators.requiredTrue],
      subscribe: [null,]
    });

    this.contactForm.valueChanges.subscribe((data: any) => this.onValueChanged(data));
    this.onValueChanged();
  }

  private onValueChanged(data?: any): void {
    /* istanbul ignore next */
    if (!this.contactForm) /* istanbul ignore next */ { return; }
    /* istanbul ignore next */
    // if form was submitted, then filled out again, remove the success message that may be there
    this.showSuccessMessage = false;
    this.contactFormSubmitted = false;
    const form: FormGroup = this.contactForm;
    for (const field in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = [];
        const control: AbstractControl | null = form.get(field);
        /* istanbul ignore else */
        if (control && !control.valid && control.dirty) {
          this.setErrors(control, field);
        }
      }
    }
  }

  private setErrors(control: AbstractControl, field: string): void {
    const messages: any = this.validationMessages[field];
    for (const key in control.errors) {
      /* istanbul ignore else */
      if (control.errors.hasOwnProperty(key)) {
        // if someone types part of an email and deletes it all, then exits the field, we only want
        // to display the required message. Not both required message and invalid message
        if (field === 'email' && key === 'email' && 'required' in control.errors) {
          continue;
        }
        this.formErrors[field].push(`${messages[key]}`);
      }
    }
  }

  public async submitForm(formData: any): Promise<void> {
    this.contactFormSubmitted = true;
    this.showSuccessMessage = false;
    if (this.isFormValid(formData.value)) {
      try {
        const observable$: Observable<string>= await this.recaptchaV3Service.execute('contactForm');

        const token: string = await lastValueFrom(observable$);
        console.log(`Got token ${token} from reCAPTCHA server call..`);

        const data = {
          name: formData.value.name,
          email: formData.value.email,
          subject: formData.value.subject,
          body: formData.value.message,
          recaptcha: token,
          subscribe: formData.value.subscribe
        }
        console.log(`Submitting form with following data: ${JSON.stringify(data)}`);
        const config: AxiosRequestConfig = {
          data: data,
          method: 'post',
          url: `${environment.developer.apis.contact}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }
        const result = await Axios.request(config);
        if (result.status !== 201) {
          throw new Error(result.data.message);
        }
        this.contactForm.reset();
        this.showSuccessMessage = true;
        //this.googleAnalyticsService.emitEvent('engagement', 'click', 'contact_form_submit');
      } catch (err) /* istanbul ignore next */ {
        console.error(err);
        this.formErrors.general.push(`An error occurred submitting your request. Please wait a few minutes and try again.`);
        this.contactFormSubmitted = false;
      }
    } else {
      // Set submit button back to enabled so that the user can resubmit the form after fixing errors
      this.contactFormSubmitted = false;
    }
  }

  private isFormValid(formData: any): boolean {
    for (const field in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = [];
      }
    }

    this.checkFormName(formData.name);
    this.checkFormEmail(formData.email);
    this.checkFormSubject(formData.subject);
    this.checkFormMessage(formData.message);

    for (const key in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(key)) {
        /* istanbul ignore else */
        if (this.formErrors[key].length > 0) {
          return false;
        }
      }
    }
    console.log(formData.acceptTosPrivacy === true);
    return formData.acceptTosPrivacy === true;
  }

  private checkFormName(name: string): void {
    if (!name || name.length === 0) {
      this.formErrors.name.push(this.validationMessages.name.required);
    } else if (name.length < 5) {
      this.formErrors.name.push(this.validationMessages.name.minlength);
    } else if (name.length > 50) {
      this.formErrors.name.push(this.validationMessages.name.maxlength);
    }
  }

  private checkFormEmail(email: string): void {
    const emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email || email.length === 0) {
      this.formErrors.email.push(this.validationMessages.email.required);
    } else if (!emailRegex.test(email)) {
      this.formErrors.email.push(this.validationMessages.email.email);
    }
  }

  private checkFormSubject(subject: string): void {
    if (!subject || subject.length === 0) {
      this.formErrors.subject.push(this.validationMessages.subject.required);
    } else if (subject && subject.length < 10) {
      this.formErrors.subject.push(this.validationMessages.subject.minlength);
    } else if (subject && subject.length > 80) {
      this.formErrors.subject.push(this.validationMessages.subject.maxlength);
    }
  }

  private checkFormMessage(message: string): void {
    if (!message || message.length === 0) {
      this.formErrors.message.push(this.validationMessages.message.required);
    } else if (message && message.length < 20) {
      this.formErrors.message.push(this.validationMessages.message.minlength);
    } else if (message && message.length > 500) {
      this.formErrors.message.push(this.validationMessages.message.maxlength);
    }
  }

}
