import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Axios, { AxiosRequestConfig } from 'axios';
import { environment } from 'src/environments/environment';
import { EmailValidator } from '../../validators/EmailValidator';
// @ts-ignore
import {lastValueFrom, Observable} from 'rxjs';
import {ReCaptchaV3Service} from "ng-recaptcha";

@Component({
  selector: 'vt-subscribe-form',
  templateUrl: './subscribe-form.component.html',
  styleUrls: ['./subscribe-form.component.scss']
})
export class SubscribeFormComponent implements OnInit {
  public environment: any;
  public subscribeForm: FormGroup;
  public subscribeFormSubmitted: boolean;
  public showSuccessMessage: boolean;
  public formErrors: any;
  public readonly validationMessages: any = {
    email: {
      required: 'Email address is required',
      email: 'Invalid email address'
    },
    recaptcha: {
      required: 'reCAPTCHA is required',
    }
  };

  constructor(private fb: FormBuilder,
              private recaptchaV3Service: ReCaptchaV3Service) {
    this.environment = environment;
    this.subscribeFormSubmitted = false;
    this.showSuccessMessage = this.subscribeFormSubmitted;
    this.subscribeForm = this.fb.group({});
    this.formErrors = {
      email: [],
      recaptcha: [],
      general: []
    };
  }

  ngOnInit(): void {
    this.subscribeFormSubmitted = false;
    this.showSuccessMessage = this.subscribeFormSubmitted;
    this.buildForm();
  }

  public clearForm(): void {
    this.subscribeForm.reset();
    this.showSuccessMessage = false;
    this.subscribeFormSubmitted = false;
  }

  private buildForm(): void {
    this.subscribeForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      email: [null, { updateOn: 'blur', validators: [Validators.required, EmailValidator.emailValidator, Validators.maxLength(100)] }],
    });

    this.subscribeForm.valueChanges.subscribe((data: any) => this.onValueChanged(data));
    this.onValueChanged();
  }

  private onValueChanged(data?: any): void {
    /* istanbul ignore next */
    if (!this.subscribeForm) /* istanbul ignore next */ { return; }
    /* istanbul ignore next */
    // if form was submitted, then filled out again, remove the success message that may be there
    this.showSuccessMessage = false;
    this.subscribeFormSubmitted = false;
    const form: FormGroup = this.subscribeForm;
    for (const field in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = [];
        const control: AbstractControl | null = form.get(field);
        /* istanbul ignore else */
        if (control && !control.valid && control.dirty) {
          this.setErrors(control, field);
        }
      }
    }
  }

  private setErrors(control: AbstractControl, field: string): void {
    const messages: any = this.validationMessages[field];
    for (const key in control.errors) {
      /* istanbul ignore else */
      if (control.errors.hasOwnProperty(key)) {
        // if someone types part of an email and deletes it all, then exits the field, we only want
        // to display the required message. Not both required message and invalid message
        if (field === 'email' && key === 'email' && 'required' in control.errors) {
          continue;
        }
        this.formErrors[field].push(`${messages[key]}`);
      }
    }
  }

  public async submitForm(formData: any): Promise<void> {
    this.subscribeFormSubmitted = true;
    this.showSuccessMessage = false;
    if (this.isFormValid(formData.value)) {
      try {
        const observable$: Observable<string>= await this.recaptchaV3Service.execute('subscribeForm');
        const token: string = await lastValueFrom(observable$);
        formData.value.recaptcha = token;
        const data = {
          email: formData.value.email,
          recaptcha: formData.value.recaptcha
        }
        const config: AxiosRequestConfig = {
          data: data,
          method: 'post',
          url: `${environment.developer.apis.subscriptions}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }
        const result = await Axios.request(config);
        if (result.status !== 201) {
          throw new Error(result.data.message);
        }
        this.subscribeForm.reset();
        this.showSuccessMessage = true;
        //this.googleAnalyticsService.emitEvent('engagement', 'click', 'contact_form_submit');
      } catch (err) /* istanbul ignore next */ {
        console.error(err);
        this.formErrors.general.push(`An error occurred submitting your request. Please wait a few minutes and try again.`);
        this.subscribeFormSubmitted = false;
      }
    } else {
      // Set submit button back to enabled so that the user can resubmit the form after fixing errors
      this.subscribeFormSubmitted = false;
    }
  }

  private isFormValid(formData: any): boolean {
    for (const field in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = [];
      }
    }

    this.checkFormEmail(formData.email);

    for (const key in this.formErrors) {
      /* istanbul ignore else */
      if (this.formErrors.hasOwnProperty(key)) {
        /* istanbul ignore else */
        if (this.formErrors[key].length > 0) {
          return false;
        }
      }
    }
    return true;
  }

  private checkFormEmail(email: string): void {
    const emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email || email.length === 0) {
      this.formErrors.email.push(this.validationMessages.email.required);
    } else if (!emailRegex.test(email)) {
      this.formErrors.email.push(this.validationMessages.email.email);
    }
  }
}
